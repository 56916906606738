// defimoon.org
// kyc.systems

//ui-component :: complex

//secondaryContainer and baseStyle are created to optimise appearance and readability

//possible troubleshoot: 
//this component is designed to render particular phrases, 
//is not intended to be flexible 

//use-case: <SubToggle isSubToggleClicked={isSubToggleClicked} onClick={() => setSubToggleClicked(!isSubToggleClicked)} />

                                                                                                    
import React from "react";


const SubToggle = ({ add, isSubToggleClicked, ...rest }) => {

    const secondaryContainer = 'py-1 px-4 h-[30px] text-sm leading-[22.4px]';

    const baseStyle = "duration-700 transition-all font-medium"
    
    return (
      <div className={`${add} invisible relative  h-[38px] items-center  flex cursor-pointer border rounded-full
                       border-main-white bg-main-black bg-opacity-[80%] `} {...rest}> 
         <div className={`z-10  ${!isSubToggleClicked ? 'text-main-black' : 'text-main-white'} ${baseStyle} 
                         ${secondaryContainer}`}>
           Pledge Phase
        </div>
        <div className={`z-10 ${isSubToggleClicked ? 'text-main-black' : 'text-main-white'} ${baseStyle}
                       ${secondaryContainer}`}>
           Investment Phase
        </div>

        <div className={` ${isSubToggleClicked ? 'w-[150px] translate-x-[119px] left-[3px]':'w-[121px] left-[3px] '} 
                      
                        transition-all rounded-full absolute 
                       bg-main-white h-[30px]`} />
      </div>
    );
  };
    
export default SubToggle;