// defimoon.org
// kyc.systems

//pop-up component
//component's code looks messy, but 
//this decision was made to 
//a)  keep the layout code clean (PopUps are called from the Layout, so we could pass the text and images as props)
//b)  to avoid producing 4 almost silimar components (A new component for each pop-up)

//The code is minimized as much as possible using the following solutions: 
//1) re-usable style sets: textStyle, headingStyle, iconStyle
//2) .map method

import React from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { motion } from 'framer-motion';

const BasePopup = (props) => {
  const { updateContextState } = useAppContext();
  const { first, second, third, fourth } = props;

  const textStyle="font-medium md:text-[20px] leading-[32px] text-main-black opacity-[80%]";
  const headingStyle="text-main-black md:mt-6 md:text-[32px] text-[20px] leading-[43px] font-semibold";
  const iconStyle = "w-10 h-10 md:mt-0 mt-10";

  const popUp = [
    {
      title: "Create ERC20 tokens with ease",
      description:
      (<><p className={`mt-6 ${textStyle}`}>
      Token Factory is a on-chain smart contract that creates and deploys ERC20 token contracts 
      for projects in a fully decentralized way.
      </p>
      <p className={`mt-4 ${textStyle}`}>
      Token Factory enables custom smart-contract creation is a fully customizable way to suit project’s needs.
      </p> 
      <p className={`mt-4 ${textStyle}`}>
      Both the Factory and the contract assembly code were audited which means that every contract 
      deployed by our Token Factory has already been audited!
      </p></>),
      iconSrc: "./tokenFactory/tokenFactory_icon1.svg",
    },
    {
      title: "Your contracts are truly your own.",
      description:
      (<><p className={`mt-6 ${textStyle}`}>
      Get full control over the entire lifecycle of your token, including transferring, 
      minting, burning, and delegating. With our intuitive platform, 
      you can seamlessly manage token allocations and distributions without any pre-requisite blockchain knowlege.
      </p>
      <p className={`mt-4 ${textStyle}`}>
      HaqqPad will deploy the contract for you upon the successful Launch, will distribute the tokens 
      securely to the investors and will transfer you full privileges over the contract.
      </p></>),
      iconSrc: "./tokenFactory/tokenFactory_icon2.svg",
    },
    {
      title: "Your contract architecture is up to you.",
      description:
      (<><p className={`mt-6 ${textStyle}`}>
        HaqqPad’s Token Factory allows you to customize your token contract beyond just the name and the allocation.
        </p>
        <p className={`mt-4 ${textStyle}`}>
        You can select different contract architectures, have your contract be deployed behind an 
        upgradable proxy, set-up multiple TGE allocations and configure your vesting schedules!
        </p>   
        <p className={`mt-4 ${textStyle}`}>
        Best part — all of the Factory contracts are pre-audited, meaning you won’t have to 
        worry about getting an expensive security audit to launch your token!
        </p></>),
      iconSrc: "./tokenFactory/tokenFactory_icon3.svg",
    },
    {
      title: "Your vesting schedule is aligned with you.",
      description:
        (<> <p className={`mt-6 ${textStyle}`}>
        Token Factory enables you to configure every aspect of your project’s 
        token distribution to your investors!
        </p>
        <p className={`mt-4 ${textStyle}`}>
        Select the options for TGE or Cliff, specify the vesting period and issue multiple 
        allocations at TGE — we’ve made our system flexible and ready to suit your project’s needs.
        </p></>),
      iconSrc: "./tokenFactory/tokenFactory_icon4.svg",
    },
  ];

  const divVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };
  
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={divVariants}
      className="flex-col md:px-[64px] px-4 py-12 lg:m-0 md:m-12 m-0 md:rounded-[10px] flex relative bg-main-white md:w-[776px] md:h-[500px]">
      <div className="absolute right-[24px] top-[32px] w-7 h-7 hover:border cursor-pointer rounded-full flex items-center justify-center">
        <img onClick={() => updateContextState({ popup: null })} alt="close_popup_button"
          className="w-4 h-4 hover:brightness-0 " src="./tokenFactory/tokenFactory_close_icon.svg" />
      </div>
      {first && popUp[0] && (
        <>
          <img className={`${iconStyle}`} src={popUp[0].iconSrc} alt="popup_icon" />
          <p className={`${headingStyle}`}>
            {popUp[0].title}
          </p>
          {popUp[0].description}
        </>
      )}
      {second && popUp[1] && (
        <>
          <img className={`${iconStyle}`} src={popUp[1].iconSrc} alt="popup_icon" />
          <p className={`${headingStyle}`}>
            {popUp[1].title}
          </p>
          {popUp[1].description}
        </>
      )}
      {third && popUp[2] && (
        <>
          <img className={`${iconStyle}`} src={popUp[2].iconSrc} alt="popup_icon" />
          <p className={`${headingStyle}`}>
            {popUp[2].title}
          </p>
          {popUp[2].description}
        </>
      )}
      {fourth && popUp[3] && (
        <>
          <img className={`${iconStyle}`} src={popUp[3].iconSrc} alt="popup_icon" />
          <p className={`${headingStyle}`}>
            {popUp[3].title}
          </p>
          {popUp[3].description}
        </>
      )}
    </motion.div>
  );
};

export default BasePopup;
