// defimoon.org
// kyc.systems

//component to wrap BasePopup.js component 
//basically the popup's background 
//also defines it's position via {children} prop and surrounding tags with styles
//allows to click outside the popup and close it (improves user experience)


import React from "react";

const ModalWindowsWrapper = ({ children, closeWindow }) => {
  return (
    <div className="fixed z-50 top-0 left-0  w-full sm:h-screen h-full flex justify-center items-center">
      <div className="z-[51]"> 
      {children}
      </div>
    
      <div
        onClick={closeWindow}
        style={{ background: "rgba(22, 22, 22, 60%)" }}
        className="z-10 absolute backdrop-blur-sm  w-full h-screen"/>
    </div>
  );
};

export default ModalWindowsWrapper;
