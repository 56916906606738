// defimoon.org
// kyc.systems

//ui-component
//Link button feautirng both white and green versions

//pay attention to type and width props
//type defines the design of a button as well as img src
//width - supporting prop to deal with width inconsistency, allows to manipulate styles with breakpoints

//use-case: <BtnLink width="your_additional_styles" type="white/green">

import React from "react";

const BtnLink = ({ children, width, type, ...rest}) => {
    const types = {
        white: "bg-main-white hover:bg-hover-gray text-main-black",
        green: "bg-main-green hover:bg-hover-green text-base-green",
      };

      const imageSrc = type === "white" ? './system/system_arrow_up_black.svg' : './system/system_arrow_up_green.svg';

    return (
      <button className={`${width} ${types[type]} flex items-center rounded-[4px] py-[7px] px-8 duration-300
                           font-medium text-base leading-[25.6px]`}{...rest}>
        {children} 
        <img src={imageSrc} 
             alt="arrow_up_icon" 
             className="ml-1"/>
      </button>
    );
  };
    
  export default BtnLink;