// defimoon.org
// kyc.systems

import React, { useState } from "react";
import BtnUni from "../ui/buttons/BtnUni";
import MenuItem from "../ui/header/MenuItem";

const Header = () => {
  
    const [isDropDown, setDropDown] = useState(false);
    const [isActive, setActive] = useState(false);

    const openDropDown = () => {
      setDropDown(!isDropDown);
      setActive(!isActive);
  };

  const [isMobMenuOpen, setMobMenuOpen] = useState(false);

  return (
    <header id="header" className="flex relative items-center justify-between bg-[#03070B] lg:h-[88px] border-b border-main-white border-opacity-[10%]
                                     py-8 lg:py-6 px-4 lg:px-12 md:px-8">
        <img className="w-[156.85px] h-[24px]" src="./header/header_haqqpad_logo.svg"  alt="haqqpad_logo"/>
        <nav className="lg:flex hidden space-x-[32px]"> 
            {/* <MenuItem isActive={isActive} setActive={setActive} onClick={openDropDown} caret text="Participate" />
            <MenuItem add='hidden' disabled caret text="About us" />
            <MenuItem  add='hidden' disabled link="" text="View launches" />
            <MenuItem add='hidden' disabled text="Dashboard" /> */}
            <a href="#how_to" className="flex text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
           How to start
          </a>
          <a href="#token_factory" className=" flex text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
            Token Factory
          </a>
        </nav>
        {/* {isDropDown && 
        (<div className="lg:flex hidden top-[65px] left-[494px] p-4 flex-col absolute bg-main-black z-[20] border
         border-main-white border-opacity-[10%]"> 
          <p className="text-main-white opacity-[30%] uppercase font-semibold text-xs leading-[14.4px]"> 
            for investors 
          </p>
          <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
            Join the Launchpad 
          </p>
          <a href="#how_to" className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
           How to start
          </a>
          <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
           Education
          </p>
          <p className="text-main-white mt-4 opacity-[30%] uppercase font-semibold text-xs leading-[14.4px]"> 
            for business
          </p>
          <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
            Apply for Launchpad
          </p>
          <a href="#token_factory" className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
            Token Factory
          </a>
        </div>)} */}
          <div className="flex items-center"> 
            <BtnUni  width="md:flex hidden invisible" type="outline">
             Buy ISLM 
            </BtnUni>
            <img onClick={() => setMobMenuOpen(!isMobMenuOpen)}
                 className="lg:hidden w-[30px] h-[30px] ml-8 hover:opacity-[80%] duration-300 cursor-pointer"
                 src={`./header/header_burger_menu_${isMobMenuOpen ? "close_" : ""}icon.svg`}
                 alt="burger_menu_icon" />
           </div>
           {isMobMenuOpen && (
            <div className="absolute flex flex-col py-8 z-50 top-[96px] right-0 left-0 bg-[#0D0D0E] min-w-[375px] w-screen h-[2000px]
            lg:px-12 md:px-8 px-4"> 
                  <div className="space-y-[16px]"> 
                    {/* <MenuItem isActive={isActive} onClick={openDropDown} caret text="Participate" />
                    {isDropDown && 
                    (<div className="lg:hidden flex  flex-col"> 
                      <p className="text-main-white opacity-[30%] uppercase font-semibold text-xs leading-[14.4px]"> 
                        for investors 
                      </p>
                      <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
                        Join the Launchpad 
                      </p>
                      <a href="#how_to" className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
                      How to start
                      </a>
                      <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
                      Education
                      </p>
                      <p className="text-main-white mt-4 opacity-[30%] uppercase font-semibold text-xs leading-[14.4px]"> 
                        for business
                      </p>
                      <p className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
                        Apply for Launchpad
                      </p>
                      <a href="#token_factory" className="text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
                        Token Factory
                      </a>
                    </div>)}
                    <MenuItem disabled caret text="About us" />
                    <MenuItem  disabled link="" text="View launches" />
                    <MenuItem disabled text="Dashboard" /> */}
                     <a href="#how_to" className="flex text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
           How to start
          </a>
          <a href="#token_factory" className=" flex text-base mt-[8px] cursor-pointer leading-[25px] text-main-white hover:opacity-[100%] opacity-[70%]"> 
            Token Factory
          </a>
                  </div>
                  

            <BtnUni  width="w-full md:hidden flex justify-center mt-[40px] invisible" type="outline">
             Buy ISLM 
            </BtnUni>
            </div>
           )}
    </header>
  );
};

export default Header;