// defimoon.org
// kyc.systems

import React from "react";
import { useAppContext } from "../hooks/useAppContext";
import { motion, AnimatePresence } from "framer-motion";

const TokenFactory = () => {
  const { updateContextState } = useAppContext();

  const tokenInfo = [
    {
      title: "Create ERC20 tokens with ease",
      description: "Your new ERC20 token.",
      popup: "createTokens",
    },
    {
      title: "Everything is under your control",
      description: "Your contracts are truly your own.",
      popup: "yourContracts",
    },
    {
      title: "Variety of choice",
      description: "Your contract architecture is up to you.",
      popup: "contractsArchitecture",
    },
    {
      title: "Model token allocations and distributions",
      description: "Your vesting scheduled is aligned with you.",
      popup: "tokenAllocation",
    },
  ];

  return (
    <div id="token_factory" className="mt-[120px] mb-[120px] flex justify-center  flex-col  lg:px-12 md:px-8 px-4 ">
      <div className="font-semibold text-center mx-auto md:text-[40px] md:leading-[52px] text-[32px] leading-[41.6px] ">
        Token Factory
        <p className="mt-4 text-base font-medium text-main-white opacity-[80%]">
          Create a token for your project instantly. We’ve taken care of it all.
        </p>
      </div>

      <div className="flex lg:flex-row flex-col flex-wrap justify-center lg:gap-[32px] lg:space-y-0 space-y-[32px] mt-[48px]">
      <AnimatePresence>
        {tokenInfo.map((info, index) => (
          <motion.div initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                      key={index}
                      className="flex relative lg:justify-start flex-col p-6 lg:w-[45%] h-[198px] rounded-[8px] border border-[#2F3942]">
            <p className="font-medium text-[20px] leading-[24px]">
              {info.title}
            </p>
            <p className="mt-4 text-base leading-[25.6px] font-medium">
              {info.description}
            </p>
            <button
              onClick={() => updateContextState({ popup: info.popup })}
              className="flex absolute bottom-[24px] font-medium text-main-green hover:text-base-green duration-300 text-base leading-[25.6px]"
            >
              More info
            </button>
          </motion.div>
        ))}
      </AnimatePresence>
      </div>
    </div>
  );
};

export default TokenFactory;