// defimoon.org
// kyc.systems

//ui-component
//button with icon is used in HowTo.js component 

//pay attention to props: width, text, number, imgSrc
//width - supporting prop to deal with width inconsistency, allows to manipulate styles with breakpoints
//imgSrc - defines image icon (choose from the const imgs list)
//text and number are props to put number and text inside the button (see the HowTo.js component)

//quite comples component in terms of inner logic. Presented in two different states in accordance to breakpoints

//use-case: <BtnIcon width="additional_styles" text="text_you_want_to_see" 
//number="any_number" imgSrc="choose from the list" />


import React, {useState} from "react";

const BtnIcon = ({ isClicked, activated, text, width, imgSrc, number, ...rest}) => {
    
    const [isHovered, setHovered] = useState(false);
    const imgs = {
        wallet: "./howTo/howTo_icon_1.svg",
        dashboard: "./howTo/howTo_icon_2.svg",
        stake: "./howTo/howTo_icon_3.svg",
        fundraising: "./howTo/howTo_icon_4.svg",
        rules: "./howTo/howTo_icon_5.svg"
      };

    return (
      <button   onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={`${width}  flex  whitespace-nowrap lg:justify-between hover:bg-main-green ${activated ? 'bg-main-green justify-between' : 'bg-[#12161B] lg:w-[402px] w-[68px] justify-center'}
                           items-center rounded-[8px] p-6 duration-300`}{...rest}>
        <div className={`${isHovered ? 'lg:text-base-green lg:flex hidden':`${activated ? 'text-base-green flex' : 'lg:flex hidden items-center text-main-white'}`} flex items-center font-medium `}> 
          <img src={imgs[imgSrc]} alt="btn_icon" 
               className={`${isHovered ? 'saturate-100':`${activated ? 'saturate-100' : 'saturate-0 brightness-[500%]' }`} mr-4`}/>
              {text}
        </div>
        <div className={`${isHovered ? 'text-base-green ':`${activated ? 'text-base-green' : 'text-main-white'}`}  font-medium opacity-[50%] `}>
                {number}
         </div>
      </button>
    );
  };
    
  export default BtnIcon;