// defimoon.org
// kyc.systems

//ui-component :: complex
//universal toggle feautirng both primary and secondary versions

//secondaryContainer and primaryContainer 
//are created to optimise appearance and readability
//for future improvement consider to add more style variables

//pay attention to prop: type
//type - prop that defines the design of a button and text-content
//type is not necessary by default. In this case it renders primary toggle

//possible troubleshoot: 
//this component is designed to render particular phrases, 
//is not intended to be flexible 
//too complex

//use-case: <Toggle type="white" />

                                                                                                    
import React from "react";


const Toggle = ({ children, add, type, isToggleClicked, setToggleClicked, ...rest}) => {

    const secondaryContainer = 'py-1 px-4 h-[30px] text-sm leading-[22.4px]';
    const primaryContainer = 'py-2 h-[42px] px-6 text-base leading-[25.6px]';

    const baseStyle = "duration-700 transition-all font-medium"
    
    return (
      <div onClick={()=>setToggleClicked(!isToggleClicked)} 
            className={`${add} relative ${type === 'white' ? 'w-[278px] h-[38px]' : 'w-[332px] h-[50px]'} items-center  flex cursor-pointer border rounded-full
                        ${type === 'white' ? 'border-main-white bg-main-black bg-opacity-[80%]':'border-main-green'}`} {...rest}> 
         <div className={`z-10 ${isToggleClicked ? `${type === 'white' ? 'text-main-white' : 'text-base-green'}`:`${type === 'white' ? 'text-main-black' : 'text-main-green'}`} ${baseStyle} 
                          ${type === 'white' ? `${secondaryContainer}`:`${primaryContainer}`}`}>
            {type === 'white' ? 'Pledge Phase':'For $ISLM Holders'}
        </div>
        <div className={`z-10 ${!isToggleClicked ? `${type === 'white' ? 'text-main-white' : 'text-base-green'}`:`${type === 'white' ? 'text-main-black' : 'text-main-green'}`} ${baseStyle}
                        ${type === 'white' ? `${secondaryContainer}`:`${primaryContainer}`}`}>
            {type === 'white' ? 'Investment Phase':'For Business'}
        </div>

        <div className={`${isToggleClicked ? `${type === 'white' ? 'w-[150px] translate-x-[119px] left-[3px]' : 'w-[182px] left-[4px]'}`
                        :`${type === 'white' ? 'w-[121px] left-[3px]' : 'w-[150px] translate-x-[172px]  left-[4px]'}`} 
                        transition-all rounded-full absolute 
                        ${type === 'white' ? 'bg-main-white h-[30px]':'bg-main-green h-[42px]'}`} />
      </div>
    );
  };
    
export default Toggle;