// defimoon.org
// kyc.systems

import React from "react";

const Footer = () => {
    const data = [
     
      {
        title: 'Social',
        menuItems: [
          { label: 'Discord', target: '_blank', link: 'https://discord.com/invite/islamiccoin' },
          { label: 'Twitter', target: '_blank', link: 'https://twitter.com/Islamic_coin' },
          { label: 'Telegram', target: '_blank', link: 'https://t.me/haqqpad_support' },
          { label: 'YouTube', target: '_blank', link: 'https://www.youtube.com/channel/UCTjvOCTDeO9H67y_6btF1NA' },
        ],
      },
    ];

  return (
    <footer className="flex flex-col py-20 px-4 lg:px-12 md:px-8">
      <div className="flex md:flex-row flex-col items-start md:justify-between">
        <img src="./header/header_haqqpad_logo.svg" alt="haqqpad_logo" />

        <div className="flex flex-wrap lg:mt-0 mt-[60px]">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex md:mr-[32px] lg:w-[185px] w-[139px] flex-col space-y-[16px] md:mt-0 mt-[32px]"
            >
              <p className="font-medium text-base leading-[25.6px]">{item.title}</p>
              {item.title === "Social"
                ? item.menuItems.map((menuItem, index) => (
                    <a
                      href={menuItem.link}
                      target={menuItem.target}
                      key={index}
                      className="font-medium cursor-pointer text-base leading-[25.6px] opacity-[50%] hover:opacity-[100%] duration-300"
                    >
                      {menuItem.label}
                      <img
                        src="./system/system_arrow_up_icon.svg"
                        alt="arrow_up_icon"
                        className="inline-block h-5 w-5 ml-1"
                      />
                    </a>
                  ))
                : item.menuItems.map((menuItem, index) => (
                    <a
                      href={menuItem.link} 
                      target={menuItem.target}
                      key={index}
                      className="font-medium cursor-pointer text-base leading-[25.6px] opacity-[50%] hover:opacity-[100%] duration-300"
                    >
                      {menuItem.label} 
                    </a>
                  ))}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[64px] text-main-white text-opacity-[80%] font-medium text-sm leading-[22.4px]">
        © HaqqPad 2024
      </div>
    </footer>
  );
};

export default Footer;