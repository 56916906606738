// defimoon.org
// kyc.systems

//custom context to render pop-ups

import React, { createContext, useState} from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [contextState, setContextState] = useState();

  const updateContextState = (newContext) => {
    setContextState((prevContext) => ({ ...prevContext, ...newContext }));
  };

  return (
    <AppContext.Provider
      value={{
        contextState,
        updateContextState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
