// defimoon.org
// kyc.systems

import React from "react";
import BtnUni from "../ui/buttons/BtnUni";
import BtnLink from "../ui/buttons/BtnLink";
import { motion } from "framer-motion"

const Hero = () => {

    const socialMediaData = [
        { name: 'Discord', icon: './hero/hero_discord_icon.svg', link: 'https://discord.com/invite/islamiccoin'},
        { name: 'Telegram', icon: './hero/hero_tg_icon.svg', link: 'https://t.me/haqqpad_support'},
        { name: 'Twitter', icon: './hero/hero_twitter_icon.svg', link: 'https://twitter.com/Islamic_coin'},
        { name: 'Youtube', icon: './hero/hero_youtube_icon.svg', link: 'https://www.youtube.com/channel/UCTjvOCTDeO9H67y_6btF1NA'},
      ];
    

  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }} style={{backgroundImage: 'url(./hero/hero_bg_image.png)'}} className="flex md:bg-[length:900px_900px] bg-[length:690px_690px] md:bg-right bg-center bg-no-repeat 
                                  flex-col h-[601px] border-b border-main-white border-opacity-[10%]
                                  lg:px-12 md:px-8 px-4 ">
        <div className="flex flex-col mt-[120px]">
            <motion.div initial={{ opacity: 0 }}
                         animate={{ opacity: 1, transition: { duration: 2 } }}
                  className="text-[31px] leading-[40.3px] font-semibold max-w-[620px]
                            md:text-[56px] md:leading-[72.8px]">
                Accelerating the Global
                Transition to Web3
            </motion.div>
            <motion.div initial={{x: -50}} animate={{ x: 0}} className="flex flex-col mt-4 font-medium text-base leading-[25.6px] opacity-[80%]">
                <span className="md:w-[300px] w-[276px]"> 
                 Empowering innovators and connecting investors in the dynamic world of blockchain technology
                </span>
            </motion.div>
            <motion.div initial={{x: -50}} animate={{ x: 0}} 
                      className="flex md:flex-row flex-col md:space-x-[16px]  md:space-y-0 space-y-[16px] mt-8">
                <a target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLScJOBusy61zxk-_bwzCUFzV4vNKDVxGThJTzO97xlPsoECCxA/viewform?usp=sf_link'> 
                <BtnLink width='md:w-[243px] max-w-[343px] flex justify-center' type='green'>
                Launch your project
                </BtnLink>
                </a>
                <BtnUni  width='md:w-[223px] max-w-[343px] flex justify-center invisible' type='outline'>
                Become an investor
                </BtnUni>

            </motion.div>
        </div>
        <motion.div initial={{y: 100}} animate={{ y: 0}}
                    className="lg:mt-[90px] md:mt-[65px] mt-[75px] md:mx-auto  flex  rounded-lg">

        {socialMediaData.map((item, index) => (
        <a
          key={index}
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className={`flex md:w-auto w-screen justify-center py-2 px-6 items-center hover:bg-opacity-[20%] duration-300 bg-main-white bg-opacity-[10%] ${
            index === 0 ? 'rounded-l-lg' : index === socialMediaData.length - 1 ? 'rounded-r-lg' : ''
          }`}
        >
          <img src={item.icon}  alt={item.name} />
         
        </a>
      ))}

        </motion.div>
    </motion.div>
  );
};

export default Hero;