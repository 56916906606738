// defimoon.org
// kyc.systems

//layout component is a wrapper for all secondary components and possible pages
//layout defines the design of a page
//in our case, as a container/wrapper, Layout.js defines that <Header.js> and <Footer.js> 
//are around the {children} prop. 
//so all supportive/secondary components will be rendered between them

//a place has been thought out in advance and left in case there is a need for a banner
//line 37



import React from "react";
import { useAppContext } from "../hooks/useAppContext";
import BasePopup from "./popups/BasePopup";

import ModalWindowsWrapper from "./popups/ModalWindowsWrapper";
import Header from "./Header";
import Footer from "./Footer";

const popups = {
  createTokens: <BasePopup first />,
  yourContracts: <BasePopup second />,
  contractsArchitecture: <BasePopup third />,
  tokenAllocation: <BasePopup fourth />
};

const Layout = ({ children }) => {
  const { contextState, updateContextState } = useAppContext();


  return (
    <div className="min-w-[360px] overflow-clip  overscroll-none">
        
      {/*place for your possible upper banner component*/}

      <div className=" flex flex-col mx-auto justify-center">
        <Header />
        {children}
        <Footer /> 
      </div>

       {/*the code below is conditonally set to render pop-ups*/}
      
      {contextState?.popup && (
        <ModalWindowsWrapper
          closeWindow={() => updateContextState({ popup: null })}
        >
          {popups[contextState.popup]}
        </ModalWindowsWrapper>
      )}
    
   
    </div>
  );
};

export default Layout;