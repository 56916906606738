// defimoon.org
// kyc.systems

//super-complex component with two data-sets
//perhaps it would be better to break it down into additional components but it will cause props-drilling
//pay attention that the tags below after a certain breakpoint take the flex-col-reverse value


import React, { useState } from "react";
import Toggle from "../ui/Toggle";
import BtnIcon from "../ui/buttons/BtnIcon";
import SubToggle from "../ui/SubToggle";
import { motion } from "framer-motion"
const HowTo = () => {
    const [activeBtnIndex, setActiveBtnIndex] = useState(0);
    const [isToggleClicked, setToggleClicked] = useState(true);
    const [isSubToggleClicked, setSubToggleClicked] = useState(true);
    const btnData = [
        { text: "Set up a wallet", number: "1", imgSrc: "wallet", 
          description: "Download and install Metamask wallet on your phone or your desktop web-browser and connect your wallet to HaqqPad. If you are a new user, you will be prompted to register on the platform.", },
        { text: "Visit Dashboard", number: "2", imgSrc: "dashboard",
        description: "Navigate to your HaqqPad Dashboard, where you’ll be able to stake $ISLM and manage your investments." },
        { text: "Stake ISLM", number: "3", imgSrc: "stake",
        description: ( <>  <p> Participation in HaqqPad Launches requires you to stake and lock $ISLM coin.</p> 
        <p className="mt-4"> Pledging your $ISLM in a project gives you access to the allocation, but locks your $ISLM until the end of the campaign. </p> </>) },
        { text: "Participate in Launch", number: "4", imgSrc: "fundraising",
        description: ( <> {!isSubToggleClicked ?  <p className="font-semibold"> Pledge Phase <p className="md:mt-3 mt-2 font-normal"> When project is in the Pledge Phase, 
        depending on the $ISLM amount staked, you are able to select a Tier and pledge the $ISLM, locking the tokens and 
        gaining access to selected project’s allocation. </p> </p> : <> <p className="font-semibold md:mt-5 mt-3"> Investment Phase  <p className="md:mt-3 mt-2 font-normal"> 
        In this phase, buy your allocation by transferring the equivalent amount in $USDT to HaqqPad's Escrow Vault. </p></p><p className="mt-3"> 
        If the launch is successful, you will receive $ISLM tokens according to the distribution schedule. Otherwise, you will still receive your $ISLM tokens, 
        and the $USDT you allocated will be refunded to your Dashboard and available for withdrawal.</p></>} </> )},
        { text: "Launch Rules", number: "5", imgSrc: "rules",
        description: (<> <p> For the Launch to be successful, the project must meet or exceed it’s campaign goal.</p>
         <p className="mt-3"> Depending on the demand for the project, HaqqPad’s algorithms will balance the supply using our Flexible Range feature, distributing the optimal supply for the project. </p>
         </>) },
    ];
    const btnData2set = [
        { text: "Application and introduction", number: "1", imgSrc: "wallet", 
          description: (
        <p>
          Apply to HaqqPad via submitting the details about your project to our{" "}
          <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScJOBusy61zxk-_bwzCUFzV4vNKDVxGThJTzO97xlPsoECCxA/viewform?usp=sf_link" className="flex hover:opacity-[80%] cursor-pointer items-center">
            application form <img src="./system/system_arrow_up_black.svg" className="brightness-[100%] invert" />
          </a>{" "}
          In case of a successful application, we will get in touch for a formal introduction with the team.
        </p>
      ), },
        { text: "Project vetting and quality assessment", number: "2", imgSrc: "dashboard",
        description: "Project are taken through a comprehensive journey to conduct all of the alignment and due-diligence checks and interviews. Projects are screened thoroughly from multiple angles, including security and business model, to guarantee only the best projects are featured on HaqqPad." },
        { text: "Official welcome to HaqqPad", number: "3", imgSrc: "stake",
        description: ( <>  <p> In case of a successful passing of HaqqPad’s assessments, projects are officially welcomed to HaqqPad platform.</p> 
        <p className="mt-4"> During this stage HaqqPad’s team will list your project on the platform and help you prepare for the campaign’s Launch! </p> </>) },
        { text: "The Launch", number: "4", imgSrc: "fundraising",
        description: ( <> <p> Depending on the demand and the success of the campaign, HaqqPad will manage the allocation supply versus the investor pool to distribute optimal amount of tokens to investors. </p> 
            <p className="mt-3">Depending on the outcome of the campaign, HaqqPad allows to provide additional launches for the project as a part of the campaign. </p></> )},
        { text: "Token distribution", number: "5", imgSrc: "rules",
        description:'When the Launch is successfully funded, project team will receive the raised $USDT funds and the tokens will be distributed among investors according to the vesting schedule' },
    ];
    const handleBtnClick = (index) => {
        if (isToggleClicked) {
            if (index >= 0 && index < btnData.length) {
                setActiveBtnIndex(index);
            }
        } else {
            if (index >= 0 && index < btnData2set.length) {
                setActiveBtnIndex(index);
            }
        }
    };
    
  return (
    <div id="how_to" className="mt-[120px] flex flex-col px-4 lg:px-12 md:px-8">
          <div className="font-semibold mx-auto text-[40px] leading-[52px] ">
                How to participate? 
            </div>
            <Toggle isToggleClicked={isToggleClicked} setToggleCliked={setToggleClicked} 
                onClick={() => setToggleClicked(!isToggleClicked)} add="mx-auto mt-6 mb-[48px]" type="green" />
            <div className="flex lg:flex-row  lg:mx-0 mx-auto flex-col-reverse lg:space-x-[32px]">
               {isToggleClicked 
               ? ( <>
                <motion.div 
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.5}}
                    className="md:flex hidden lg:flex-col lg:space-y-[24px] lg:space-x-0 space-x-[14px] lg:mt-0 mt-[32px]">
               
                {btnData.map((btn, index) => (
                    <BtnIcon
                        key={index}
                        width="w-[402px]"
                        text={btn.text}
                        number={btn.number}
                        imgSrc={btn.imgSrc}
                        activated={index === activeBtnIndex}
                        onClick={() => handleBtnClick(index)}
                        />
                    ))}
               
                </motion.div>
                    <div className="md:hidden flex space-x-[8px] mt-[32px]"> 
                        <button onClick={() => handleBtnClick(activeBtnIndex - 1)} 
                                className={`flex items-center justify-center rounded-[8px] border w-[167px] h-[40px]  
                                            ${activeBtnIndex > 0 ? 'border-main-green' : 'border-main-white border-opacity-[30%]'}`}> 
                            <img className={`rotate-180 saturate-0 ${activeBtnIndex > 0 && 'saturate-100'}`}
                                 src="./howTo/howTo_arrow_switcher_icon.svg" 
                                 alt="arrow_switcher_left" /> 
                        </button>
                        <button onClick={() => handleBtnClick(activeBtnIndex + 1)} 
                            className={`flex items-center justify-center rounded-[8px] border w-[167px] h-[40px]  
                            ${activeBtnIndex <= 3 ? 'border-main-green' : 'border-main-white border-opacity-[30%]'}`}> 
                            <img className={`saturate-0 ${activeBtnIndex <= 3 && 'saturate-100'}`}
                                    src="./howTo/howTo_arrow_switcher_icon.svg" 
                                    alt="arrow_switcher_left" /> 
                        </button>
                    </div>
                <div className="md:hidden flex mx-auto mt-[32px] space-x-[6px]">
                {btnData.map((btn, index) => (
                    <button onClick={() => handleBtnClick(index)} key={index} 
                    className={`${index === activeBtnIndex ? 'bg-[#04EB92]' : 'bg-main-gray'} w-[64px] h-[6px] rounded-[2px]`}>
                    </button>
                    ))}
                </div>
                    
                <div style={{backgroundImage: 'url(./howTo/bg_1.png)'}}
                    className="bg-cover bg-center justify-center flex relative bg-[#12161B]  py-6 px-5 rounded-[11px]
                                lg:w-full lg:max-w-none md:max-w-[730px] md:w-auto w-[343px] md:h-[456px] h-[422px]"> 
                    {activeBtnIndex === 3 && <div className="mx-auto"> <SubToggle isSubToggleClicked={isSubToggleClicked} onClick={() => setSubToggleClicked(!isSubToggleClicked)} /> </div>}
                    <div className="absolute bottom-[24px]"> 
                        <div className="flex items-center mb-5 md:px-0 px-4"> 
                            <img
                            src={`./howTo/howTo_icon_${activeBtnIndex + 1}.svg`}
                            alt={`${btnData[activeBtnIndex].imgSrc} icon`}
                            className="mr-4 w-6 h-6 saturate-0 brightness-[500%]"/>
                            <p className="md:text-[20px] md:leading-[24px] font-medium">{btnData[activeBtnIndex].text}</p>
                        </div>
                        <p className="lg:max-w-[796px] max-w-[685px] md:px-0 px-4 lg:text-[20px] text-[18px] leading-[23.94px] lg:leading-[26.6px]"> 
                        {btnData[activeBtnIndex].description}
                        </p>
                    </div>
                </div>  </>) 
                :
                <>
                <motion.div  
                     initial={{ opacity: 0, x: 20 }}
                     whileInView={{ opacity: 1, x: 0 }}
                     exit={{ opacity: 0, x: 20 }}
                     transition={{ duration: 0.5}}
                    className="md:flex hidden lg:flex-col lg:space-y-[24px] lg:space-x-0 space-x-[14px] lg:mt-0 mt-[32px]">
                {btnData2set.map((btn, index) => (
                    <BtnIcon
                        key={index}
                        width="w-[402px]"
                        text={btn.text}
                        number={btn.number}
                        imgSrc={btn.imgSrc}
                        activated={index === activeBtnIndex}
                        onClick={() => handleBtnClick(index)}
                        />
                    ))}
                </motion.div>
                <div className="md:hidden flex space-x-[8px] mt-[32px]"> 
                        <button onClick={() => handleBtnClick(activeBtnIndex - 1)} 
                                className={`flex items-center justify-center rounded-[8px] border w-[167px] h-[40px]  
                                            ${activeBtnIndex > 0 ? 'border-main-green' : 'border-main-white border-opacity-[30%]'}`}> 
                            <img className={`rotate-180 saturate-0 ${activeBtnIndex > 0 && 'saturate-100'}`}
                                 src="./howTo/howTo_arrow_switcher_icon.svg" 
                                 alt="arrow_switcher_left" /> 
                        </button>
                        <button onClick={() => handleBtnClick(activeBtnIndex + 1)} 
                            className={`flex items-center justify-center rounded-[8px] border w-[167px] h-[40px]  
                            ${activeBtnIndex <= 3 ? 'border-main-green' : 'border-main-white border-opacity-[30%]'}`}> 
                            <img className={`saturate-0 ${activeBtnIndex <= 3 && 'saturate-100'}`}
                                    src="./howTo/howTo_arrow_switcher_icon.svg" 
                                    alt="arrow_switcher_left" /> 
                        </button>
                    </div>
                    <div className="md:hidden flex mx-auto mt-[32px] space-x-[6px]">
                        {btnData.map((btn, index) => (
                            <button onClick={() => handleBtnClick(index)} key={index} 
                            className={`${index === activeBtnIndex ? 'bg-[#04EB92]' : 'bg-main-gray'} w-[64px] h-[6px] rounded-[2px]`}>
                            </button>
                            ))}
                    </div>
                <div style={{backgroundImage: 'url(./howTo/bg_2.png)'}}  className="bg-cover bg-center justify-center flex relative bg-[#12161B] py-6 px-5 rounded-[11px]
                                lg:w-full lg:max-w-none md:max-w-[730px] md:w-auto w-[343px] md:h-[456px] h-[422px]"> 
                    <div className="absolute bottom-[24px]"> 
                        <div className="flex items-center mb-5 md:px-0 px-4"> 
                            <img
                            src={`./howTo/howTo_icon_${activeBtnIndex + 1}.svg`}
                            alt={`${btnData2set[activeBtnIndex].imgSrc} icon`}
                            className="mr-4 w-6 h-6 saturate-0 brightness-[500%]"/>
                            <p>{btnData2set[activeBtnIndex].text}</p>
                        </div>
                        <p className="lg:max-w-[796px] max-w-[685px] md:px-0 px-4 lg:text-[20px] text-[18px] leading-[23.94px] lg:leading-[26.6px]"> 
                        {btnData2set[activeBtnIndex].description}
                        </p>
                    </div>
                </div>  </>} 
              
            </div>
    </div>
  );
};
export default HowTo;