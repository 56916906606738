// defimoon.org
// kyc.systems

import React from "react";
import BtnLink from "../ui/buttons/BtnLink";

const Careers = () => {   
    

  return (
    <div className="flex hidden relative justify-center items-center text-center flex-col bg-main-white
                    lg:px-12 md:px-8 px-4 md:py-[80px] py-[60px]">
        <img className="absolute lg:flex hidden bottom-0 right-[48px]" 
             src="./stakeFeatures/stakeFeatures_lines.svg" alt="lines" />
        <img className="absolute lg:flex hidden bottom-0 rotate-180 left-[48px]" 
             src="./stakeFeatures/stakeFeatures_lines.svg" alt="lines" />
       
            <div className="font-semibold  flex-col flex mx-auto text-[40px] text-main-black leading-[52px] ">
              Careers
              <p className="mt-4 leading-[25.6px] text-base font-medium w-[360px] text-main-black opacity-[80%]"> 
              Join our team of skilled professionals and help us create innovative, 
              ethical and sustainable products.
              </p>
            </div>
            <a href="https://islamiccoin.net/join-us" target="_blank" rel="noreferrer">
              <BtnLink type="green" width="w-[206px] mt-[48px]">
                View open roles
              </BtnLink>
            </a>
    </div>
  );
};

export default Careers;