import Careers from "./components/Careers";
import HaqqPros from "./components/HaqqPros";
import Hero from "./components/Hero";
import HowTo from "./components/HowTo";
import Layout from "./components/Layout";
import MiddleBanner from "./components/MiddleBanner";
import StakeFeatures from "./components/StakeFeatures";
import TokenFactory from "./components/TokenFactory";
import AppContextProvider from "./contexts/AppContext";

function App() {
  return (
    <AppContextProvider>
      <Layout> 
        <Hero />
        <HaqqPros />
        <StakeFeatures />
        <HowTo />
        <MiddleBanner />
        <TokenFactory />
        <Careers />
     </Layout>
    </AppContextProvider>
  );
}

export default App;
