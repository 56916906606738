// defimoon.org
// kyc.systems

//ui-component
//universal button feautirng both primary and outline designs

//pay attention to props: width and type
//width - supporting prop to deal with width inconsistency, allows to manipulate styles with breakpoints
//type - prop that defines the design of a button

//use-case: <BtnUni width="your_additional_styles" type="primary/outline">

import React from "react";

const BtnUni = ({ children, width, type, ...rest}) => {
    const types = {
        primary: "bg-main-green hover:bg-hover-green text-base-green",
        outline: "border border-main-white hover:bg-[#FFFFFF3D] text-main-white",
      };

    return (
      <button className={`${width} ${types[type]} rounded-md py-[7px] px-8
                            font-medium text-base leading-[25.6px] duration-300`}{...rest}>
        {children}
      </button>
    );
  };
    
  export default BtnUni;