// defimoon.org
// kyc.systems


import React from "react";
import { motion, AnimatePresence } from "framer-motion"

const itemsData = [
    {
      title: "Tier-based allocation",
      description:
        "HaqqPad's Tier-based allocation system allows stakers to participate as early investors. More staked tokens mean higher allocation multipliers and earlier accesses.",
    },
    {
      title: "Expert team",
      description: "Our vetting team, comprising blockchain experts, meticulously evaluates projects for months/weeks before approval.",
    },
    {
      title: "Trust factor",
      description: "Projects reveal token vesting schedules and lock the campaign allocation in HaqqPad’s Escrow Vault, enhancing trust, backed and secured by HaqqPad's technology.",
    },
  ];

const StakeFeatures = () => {   
    

  return (
    <div className="flex relative flex-col pb-[118px] bg-main-white
                    lg:px-12 md:px-8 px-4">

        <img className="absolute lg:flex hidden lg:bottom-0 bottom-[25%] lg:right-[48px] md:right-[32px]" src="./stakeFeatures/stakeFeatures_lines.svg" alt="lines" />
        <img className="absolute lg:flex hidden lg:bottom-0 bottom-[25%]  rotate-180 lg:left-[48px] md:left-[32px]" src="./stakeFeatures/stakeFeatures_lines.svg" alt="lines" />
       
            <div className="text-[32px] leading-[41.6px] font-semibold  mx-auto mt-20  text-main-black 
                            md:text-[40px] text-center md:leading-[52px]">
               What are the benefits for $ISLM stakers?
            </div>

        <div className="mt-[48px] mx-auto  flex lg:flex-row flex-col lg:space-y-0 space-y-[40px] lg:space-x-[64px]"> 
          <AnimatePresence>
            {itemsData.map((item, index) => (
                <motion.div initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                            className="lg:max-w-[307px] max-w-[1000px]"> 
                        <img src="./stakeFeatures/stakeFeatures_icon.svg" 
                            alt="card_icon"/>
                    <p className="text-main-black text-[24px] font-medium mt-4 leading-[32px]">
                      {item.title}
                    </p>
                    <p className="text-main-black mt-2 font-medium text-base text-opacity-[80%] leading-[26px]">
                      {item.description}
                    </p>
                </motion.div>
            ))}
          </AnimatePresence>
        </div>
    </div>
  );
};

export default StakeFeatures;