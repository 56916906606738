// defimoon.org
// kyc.systems

//custom hook in addition to custom context 
//created to avoid props-drilling

import { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'

export const useAppContext = () => useContext(AppContext)
