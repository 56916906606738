// defimoon.org
// kyc.systems

//this component is mentioned in README as the one for which custom styles were created
//(bg-size-200 bg-pos-0 hover:bg-pos-100)

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion"

const cardData = [
    {
      title: "Exclusive Access for Stakers",
      description:
        "Stakers gain exclusive access to pre-approved, high-quality projects, securing token allocations through ISLM staking. Projects are evaluated by blockchain experts, enhancing reliability in project selection.",
      iconSrc: "./haqqpros/haqqpros_icon1.svg",
    },
    {
      title: "Transparent Project Vetting",
      description:
        "HaqqPad's vetting team meticulously evaluates and approves projects, maintaining a high standard of quality and reliability. This ensures that stakers' investments are directed towards credible ventures in Web3.",
      iconSrc: "./haqqpros/haqqpros_icon2.svg",
    },
    {
      title: "ISLM Staking Tiers",
      description:
        "Stakers can potentially secure guaranteed allocations in promising projects by staking ISLM tokens, utilizing a tier system that reflects the project's potential. Rigorous evaluation ensures optimal investment opportunities.",
      iconSrc: "./haqqpros/haqqpros_icon3.svg",
    },
    {
      title: "Haqq Chain Ethos",
      description:
        "Projects listed on HaqqPad adhere to the principles of Haqq Chain, a shariah-compliant framework, highlighting the platform's commitment to ethical and responsible blockchain innovation.",
      iconSrc: "./haqqpros/haqqpros_icon4.svg",
    },
  ];

const HaqqPros = () => {   
    const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="mt-[120px] pb-[120px] flex flex-col
                     lg:px-12 md:px-8 px-4 ">
        <div className="flex-col mx-auto text-center"> 
            <div className="font-semibold text-[32px] leading-[41.6px]
                            md:text-[40px] md:leading-[52px] ">
                What is HaqqPad? 
            </div>
            <p className="max-w-[740px] min-w-[343px] opacity-[80%] font-medium leading-[25.6px] mt-4">
                HaqqPad is a Launchpad platform that grants non-US stakers early access to 
                promising blockchain projects. It employs advanced technology 
                for secure token locking, utilizing smart contracts to ensure 
                transparent distribution upon development milestones and vesting periods.
            </p>
        </div>

        <div className="mt-[48px] flex md:flex-row flex-col justify-center flex-wrap"> 
          <AnimatePresence>
            {cardData.map((card, index) => (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                    key={index}
                    onClick={() => setActiveIndex(index)}
                    className={`flex font-medium p-10 flex-col bg-size-200 bg-pos-0 hover:bg-pos-100 lg:h-[258px] max-w-[635px] basis-1/2 border border-main-gray transition-all duration-300 ${
                    activeIndex === index
                        ? "bg-gradient-to-br  from-[#010305] via-[#2A7458] to-[#467FC1]"
                        : "bg-main-black  hover:bg-gradient-to-br from-[#010305] via-[#2A7458] to-[#467FC1]"
                    }`}
                    >
                    <img className={`${ activeIndex === index ? "saturate-0 brightness-200" : "saturate-100"} 
                                  duration-300 transition-all w-8 h-8`}
                    src={card.iconSrc}
                    alt="section_icon"
                    />
                    <p className="text-[24px] mt-6 leading-[32px]">{card.title}</p>
                    {activeIndex === index && (
                    <motion.p initial ={{y:20}}
                              animate={{ opacity: activeIndex === index ? 1 : 0, y:0 }} 
                              transition={{ duration: 0.5 }} 
                              className="opacity-[80%] leading-[25px] text-base  mt-3">{card.description}</motion.p>
                    )}
                </motion.div>
            ))}
        </AnimatePresence>
        </div>
    </div>
  );
};

export default HaqqPros;