// defimoon.org
// kyc.systems

import React from "react";
import BtnLink from "../ui/buttons/BtnLink";

const MiddleBanner = () => {   

  return (
    <div className="hidden md:flex-row flex-col mt-[120px] lg:mx-12 md:mx-8 mx-4 py-8 px-6 items-center justify-between bg-[#12161B] rounded-[8px]">
        <div className="flex flex-col">  
            <p className="font-medium text-[24px] leading-[32px]"> 
              Learn more about us
            </p>
            <p className="opacity-[80%] mt-2 text-base leading-[25.6px] font-medium"> 
               Read Platform’s Rules and the details of Launch mechanics in our wiki
            </p>
        </div>
        <BtnLink type="white" width="w-[159px] md:mt-0 mt-[32px]"> 
          More info
        </BtnLink>
        
    </div>
  );
};

export default MiddleBanner;